<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3"
      >
        <v-card
          color="white"
          width="100%"
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
            <div class="d-flex">
              <div
                v-if="!noTitle"
                class="d-flex flex-column"
              >
                <span class="text-body-1 font-weight-bold">{{ title }}</span>
                <span
                  class="text-body-1 mt-1"
                >
                  {{ subtitle }}
                </span>
              </div>
              <div
                v-else
                class="d-flex flex-column"
              >
                <span
                  class="text-body-1"
                  :class="{ 'mt-1': title }"
                >
                  <slot name="message" />
                </span>
              </div>
            </div>

            <v-icon
              v-if="showArrow"
              color="#9b9b9b"
              class="ma-0"
              size="25"
            >
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiChevronRight,
} from '@mdi/js'

export default {
  name: 'ActionCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    avatarColor: {
      type: String,
      default: '#9b9b9b',
    },
    iconColor: {
      type: String,
      default: '#ffffff',
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      icons: {
        mdiChevronRight,
      },
    }
  },
}
</script>
