<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3 cursor-pointer"
        @click="$emit('changePaymentMethod')"
      >
        <v-card
          color="white"
          width="100%"
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
            <div class="d-flex">
              <div class="d-flex flex-column">
                <!-- <span class="text-body-1 font-weight-bold">{{ title }}</span> -->
                <div class="d-flex mt-3">
                  <v-avatar
                    :style="`background-color: #f2f2f2 !important;`"
                    class="v-avatar-light-bg primary--text mr-3"
                    size="50"
                  >
                    <v-icon
                      color="primary"
                      class="ma-0"
                      size="25"
                    >
                      {{ icons.mdiCreditCardOutline }}
                    </v-icon>
                  </v-avatar>

                  <span class="d-flex flex-column">
                    <span class="text-capitalize">{{ cardNumber }}</span>
                    <span class="">{{ cardDate }}</span>
                  </span>
                </div>
              </div>
            </div>

            <v-icon
              v-if="showArrow"
              color="#9b9b9b"
              class="ma-0"
              size="25"
            >
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiChevronRight,
  mdiCreditCardOutline,
} from '@mdi/js'

export default {
  name: 'ActionCard',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    cardNumber: {
      type: String,
      default: '',
    },
    cardDate: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      icons: {
        mdiChevronRight,
        mdiCreditCardOutline,
      },
    }
  },
}
</script>
