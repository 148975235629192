<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-1 py-1">
            <v-col
              cols="12"
              class="py-1 px-3 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <h1 class="text-h6 font-weight-bold black--text">
                  {{ t('game.pay_booking') }}
                </h1>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-0 no-bg-hover font-weight-bold text-body-1"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin()"
                >
                  {{ t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text
        v-if="dataParams"
        class="pt-0"
      >
        <v-card-title class="text-body-1 font-weight-bold black--text px-0">
          <span>1. {{ $t('game.review_details') }}</span>
        </v-card-title>

        <booking-card
          :title="dataParams.sport_name"
          :date="`${formatStdDate(dataParams.reservation_date, 'date', true, $i18n.locale)} ${formatStdDate(dataParams.start_time, 'time', true, $i18n.locale)} - ${formatStdDate(dataParams.end_time, 'time', true, $i18n.locale)}`"
          :facility-name="dataParams.facility_name"
          :field-name="dataParams.field_name"
          :total="dataParams.total_price_with_fanaty_fee"
          :subtotal="dataParams.reservation_base_price"
          :fee-taxes="dataParams.service_fee_taxes"
        ></booking-card>
      </v-card-text>

      <v-card-text
        v-if="dataParams"
        class="pt-0"
      >
        <policy-card
          :no-title="true"
        >
          <template #message>
            <span
              v-if="datepass(dataParams.cancelation_deadline)"
              class="font-weight-bold"
            >
              {{ $t('game.non_refundable') }}
            </span>
            <span v-else>
              <span class="font-weight-bold">{{ $t('game.cancellation_policy_msg_short') }}</span>
              {{ `${$t('game.before')} ${formatStdDate(dataParams.cancelation_deadline, 'monthtime', true, $i18n.locale)}` }}
            </span>
          </template>
        </policy-card>
      </v-card-text>

      <v-card-text
        v-if="dataParams"
        class="pt-0"
        :style="!hasLastPaymentMethod ? 'padding-bottom: 150px;' : ''"
      >
        <v-card-title class="text-body-1 font-weight-bold black--text px-0 pt-0">
          2. {{ $t('game.how_to_pay') }}
        </v-card-title>

        <!-- // remove team is-challenge -->
        <!-- :disabled="(dataParams && !dataParams.can_split_payment) || !privateLanding" -->
        <pay-card
          :selected="paymentType"
          :player-amount="dataParams.players_final_amount"
          :player-qty="dataParams.players_qty"
          :total="dataParams.total_price_with_fanaty_fee"
          :date="`${formatStdDate(dataParams.payment_deadline, 'day', true, $i18n.locale)} ${$t('game.of')} ${formatStdDate(dataParams.payment_deadline, 'month-str', true, $i18n.locale)}`"
          :time="formatStdDate(dataParams.payment_deadline.split(' ')[1], 'time', true, $i18n.locale)"
          :disabled="(dataParams && !dataParams.can_split_payment)"
          :is-challenge="dataParams.is_challenge === 'Y'"
          :show-edit="dataParams.is_challenge !== 'Y'"
          @updateSelected="(val) => setPaymentType(val)"
          @splitParticipants="openUpdateSplit(true)"
        ></pay-card>
      </v-card-text>

      <v-card-text
        v-if="hasLastPaymentMethod"
        class="pt-0"
        :style="'padding-bottom: 150px;'"
      >
        <v-card-title class="text-body-1 font-weight-bold black--text px-0 pt-0">
          3. {{ $t('payment_methods.payment_method') }}
        </v-card-title>

        <payment-method-card
          :title="$t('payment_methods.payment_method')"
          :card-number="`${dataParams.last_payment_method.brand} ${dataParams.last_payment_method.last4}`"
          :card-date="`Exp. ${dataParams.last_payment_method.exp_month}/${dataParams.last_payment_method.exp_year}`"
          @changePaymentMethod="onChangePaymentMethod"
        >
        </payment-method-card>
      </v-card-text>

      <v-card-actions
        v-if="!userLogged"
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          @click="onLogin(true)"
        >
          <span>{{ t('tooltip.continue') }}</span>
        </v-btn>
      </v-card-actions>

      <v-card-actions
        v-if="!!userLogged"
        class="pay-card-action py-3 px-4 d-flex flex-column justify-center"
      >
        <v-btn
          block
          rounded
          large
          color="primary"
          type="submit"
          class="text-body-1 font-weight-bold my-2"
          style="border-radius: 33px; text-transform: initial;"
          @click="onContinueAndPay"
        >
          {{ !!userLogged && dataParams && dataParams.is_organizer && hasLastPaymentMethod ? t('reservations.confirm_and_pay') : t('tooltip.continue') }}
        </v-btn>
      </v-card-actions>

      <transition name="fade">
        <div v-show="!!userLogged && dataParams && dataParams.is_organizer && showChangePaymentMethod">
          <!-- v-if="!userLogged || (!!userLogged && dataParams && dataParams.is_organizer)" -->
          <!-- class="pay-card-action py-3 d-flex- flex-column" -->
          <!-- style="visibility: hidden;" -->
          <div>
            <div class="d-flex mb-2">
              <span class="text-body-1 font-weight-bold">{{ $t('game.pay_with') }}</span>
              <v-spacer />
              <v-btn
                v-if="hasLastPaymentMethod"
                class="close-btn"
                icon
                small
                style="background-color: #f2f2f2"
                @click="showChangePaymentMethod = false"
              >
                <v-icon color="#3f3f3f">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </div>

            <div
              id="express-checkout-element"
            ></div>

            <v-btn
              class="text-body-1 font-weight-bold my-2"
              style="border-radius: 33px; text-transform: initial;"
              color="primary"
              rounded
              large
              block
              :outlined="!onlyNewCard"
              @click="onContinue"
            >
              <span>{{ $t('pay_video.credit_card_new') }}</span>
            </v-btn>
          </div>

          <div
            v-show="containarLoading"
            class="pay-card-action py-3 justify-center align-center"
            :class="{'d-flex': containarLoading}"
            style="min-height: 150px;"
          >
            <div
              id="loader"
              class="loader"
            >
            </div>
          </div>
        </div>
      </transition>

      <checkout-pay
        v-if="dataParams"
        :is-open.sync="isDialogPay"
        :taxes="dataParams.fanaty_fee ? dataParams.fanaty_fee : 0"
        :subtotal="dataParams.total"
        :total="dataParams.total_with_fanaty_fee"
        :total-format="'usd'"
        :payment-methods="paymentMethods"
        :has-user="false"
        :user-data="!!userData ? userData : false"
        @openClose="openUpdatePay"
        @save="onPay"
      />

      <split-participants
        v-if="dataParams"
        :is-open.sync="isDialogSplit"
        :options="dataParams.split_payment_amounts ? dataParams.split_payment_amounts : []"
        :selected="selectedSplit"
        :max-players="dataParams.max_players_qty"
        @updateSelected="(val) => selectedSplit = val"
        @openClose="openUpdateSplit"
        @save="onChangePlayersQty"
      />
    </div>
  </div>
</template>

<script>
import {
  mdiCreditCardOutline, mdiFoodAppleOutline, mdiCalendar, mdiClose, mdiAlert,
} from '@mdi/js'
import {
  ref, computed, watch, onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import { required, minLengthValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import {
  useRouter, formatCurrency, formatStdDate, detectarNavegador,
} from '@core/utils'
import themeConfig from '@themeConfig'
import useCryptoJs from '@core/utils/useCryptoJs'
import usePhone from '@core/utils/usePhone'
import useLogData from '@core/utils/useLogData'
import { loadStripe } from '@stripe/stripe-js'
import {
  getLandingUserPaymentMethods,
  getReservationPay,
  getReservationCode,
  getReservationOpenCode,
  changeMatchPlayersQty,
} from '@api'
import { showError } from '@/@core/utils/toasted'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import BookingCard from '@/views/pay-booking/components/booking-card/BookingCard.vue'
import PolicyCard from '@/views/pay-booking/components/policy-card/PolicyCard.vue'
import PaymentMethodCard from '@/views/pay-booking/components/payment-method-card/PaymentMethodCard.vue'
import CheckoutPay from '@/views/video-checkout/components/CheckoutPay.vue'
import SplitParticipants from '@/views/reservation/components/split-participants/SplitParticipants.vue'

import PayCard from '@/views/reservation/components/pay-card/PayCard.vue'

export default {
  components: {
    AppBarUserAvatar,
    BookingCard,
    PolicyCard,
    PaymentMethodCard,
    CheckoutPay,
    PayCard,
    SplitParticipants,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { t, tInContext } = useUtils()
    const { userData, encrypt, decryptData } = useCryptoJs()
    const { phoneCodeOptions } = usePhone()
    const { route, router } = useRouter()
    const { logData, updateLogData } = useLogData()

    const privateLanding = ref(null)
    const dataParams = ref(null)
    const isDialogPay = ref(false)
    const isDialogSplit = ref(false)
    const matchCode = ref(null)
    const reservationCode = ref(null)

    // remove team
    const teamSelected = ref(null)
    const paymentMethods = ref([])
    const paymentType = ref(1)
    const selectedSplit = ref(-1)
    const userLogged = ref(null)

    const containarLoading = ref(true)
    const isLoading = ref(false)
    const isInitialized = ref(false)
    const isDeclined = ref(false)
    const form = ref(null)
    let stripe
    let elements
    let expressCheckoutElement
    const showChangePaymentMethod = ref(false)
    const hasLastPaymentMethod = computed(() => dataParams.value && dataParams.value.last_payment_method)
    const isMozilla = computed(() => {
      const nav = detectarNavegador()

      return nav.includes('firefox')
    })
    const onlyNewCard = ref(true)

    const datepass = dateString => {
      const inputDate = new Date(dateString)
      const currentDate = new Date()

      return inputDate < currentDate
    }

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const openUpdateSplit = async val => {
      isDialogSplit.value = val
    }

    // remove team
    const onShowNotOrganizer = async () => {
      const msg = tInContext('game.only_organizer', vm)
      showError(msg)
    }

    const onLogin = async (showPay = false) => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = matchCode.value
      logData.value.reservationCode = reservationCode.value
      logData.value.redirect = 'reservation'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'
      logData.value.showPay = showPay
      logData.value.payType = paymentType.value

      // remove team
      logData.value.team = teamSelected.value
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const payOrderVideo = async body => {
      const encryptInfo = encrypt(body)
      const respPay = await getReservationPay(reservationCode.value, { info: encryptInfo })
      if (respPay && respPay.ok) {
        router.replace({
          name: userData.value.avatar === null || userData.value.avatar.includes('no-profile-pic') ? 'profile-picture' : 'match',
          params: {
            code: matchCode.value,
            joined: true,
            joinedMessage: respPay.data.notify_new_slot,
            joinedFrom: 'reservation',
            team: teamSelected.value,
          },
        })
      }
    }

    const onPay = async cardInfo => {
      const body = {
        amount: dataParams.value.total_price_with_fanaty_fee,
        payment_method_id: cardInfo.payment_method_id,
        payment_type: paymentType.value === 1 ? 'full' : 'per_player',
        card_info: cardInfo,
      }
      await payOrderVideo(body)
    }

    const onContinueAndPay = async () => {
      if (dataParams.value && dataParams.value.is_organizer && hasLastPaymentMethod.value) {
        const cardInfo = {
          payment_method_id: dataParams.value.last_payment_method.payment_method_id,
          save_card: null,
          name: null,
          number: null,
          exp_month: null,
          exp_year: null,
          cvc: null,
          country: null,
          zip_code: null,
          email: null,
        }
        await onPay(cardInfo)
      } else {
        // remove team
        onShowNotOrganizer()
      }
    }

    const closeContainarLoading = () => {
      setTimeout(() => {
        const container = document.getElementById('express-checkout-element').parentNode
        container.classList.add('pay-card-action', 'py-3', 'px-4')
        setTimeout(() => {
          containarLoading.value = false
        }, 600)
      }, 1000)
    }

    const updateElements = () => {
      if (expressCheckoutElement) {
      // Desmontar el elemento actual
        expressCheckoutElement.unmount()

        const appearance = {
          variables: {
            borderRadius: '33px',
          },
          rules: {
            '.Input, .Block': {
              backgroundColor: 'transparent',
              border: '1px solid var(primary-shade)',
            },
          },
        }
        const centAmount = parseInt(paymentType.value === 1 ? ((dataParams.value.total_price_with_fanaty_fee * 100) || 1000) : ((dataParams.value.players_final_amount * 100) || 1000), 10)

        // Crear una nueva instancia de elements con las nuevas opciones
        elements = stripe.elements({
          mode: 'payment',
          currency: 'usd',
          amount: centAmount,
          appearance,
          paymentMethodCreation: 'manual',
        })

        // Crear un nuevo elemento
        expressCheckoutElement = elements.create('expressCheckout')
        expressCheckoutElement.mount('#express-checkout-element')
      }
    }

    const initStripe = async () => {
      try {
        const expressContent = document.getElementById('express-checkout-element')
        const centAmount = paymentType.value === 1 ? ((dataParams.value.total_price_with_fanaty_fee * 100) || 1000) : ((dataParams.value.players_final_amount * 100) || 1000)

        if (expressContent) {
          const publishableKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY
          stripe = await loadStripe(publishableKey)
          const paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
              label: 'Demo total',
              amount: centAmount,
            },
            requestPayerName: true,
            requestPayerEmail: true,
          })

          paymentRequest.canMakePayment().then(result => {
            if (result) {
              const appearance = {
                variables: {
                  borderRadius: '33px',
                },
                rules: {
                  '.Input, .Block': {
                    backgroundColor: 'transparent',
                    border: '1px solid var(primary-shade)',
                  },
                },
              }
              elements = stripe.elements({
                mode: 'payment',
                currency: 'usd',
                amount: centAmount,
                appearance,
                paymentMethodCreation: 'manual',
              })

              expressCheckoutElement = elements.create('expressCheckout')
              expressCheckoutElement.mount('#express-checkout-element')

              expressCheckoutElement.on('ready', ({ availablePaymentMethods }) => {
                if (availablePaymentMethods) {
                  const container = document.getElementById('express-checkout-element').parentNode
                  container.classList.add('pay-card-action', 'py-3', 'px-4')
                  onlyNewCard.value = false

                  closeContainarLoading()
                }
              })

              expressCheckoutElement.on('confirm', async () => {
                const elementsSubmit = await elements.submit()
                const { error: submitError } = elementsSubmit
                if (submitError) {
                  console.error(submitError)
                }

                stripe
                  .createPaymentMethod({
                    elements,
                    params: {
                      billing_details: {
                        name: '',
                      },
                    },
                  })
                  .then(async resultPaymentMethod => {
                    if (resultPaymentMethod.paymentMethod.id) {
                      const cardInfo = {
                        payment_method_id: resultPaymentMethod.paymentMethod.id,
                        save_card: null,
                        name: null,
                        number: null,
                        exp_month: null,
                        exp_year: null,
                        cvc: null,
                        country: null,
                        zip_code: null,
                        email: null,
                      }
                      await onPay(cardInfo)
                    }
                  })
              })
            }
            isLoading.value = false
            isInitialized.value = true

            closeContainarLoading()
          }).catch(e => {
            console.error({ e })
            isLoading.value = false
            isInitialized.value = true

            closeContainarLoading()
          })
        }
      } catch (e) {
        console.error({ e })

        closeContainarLoading()
      }
    }

    const initReservation = async () => {
      selectedSplit.value = -1
      userLogged.value = await JSON.parse(localStorage.getItem('userData'))
      privateLanding.value = !!userLogged.value

      // remove team
      // const { code, team, showPay } = route.value.params
      const { code, team, payType } = route.value.params

      reservationCode.value = code
      teamSelected.value = `${team}`.toUpperCase()
      paymentType.value = payType || 1

      let response = null
      let responsePaymentMethods = null
      if (userLogged.value) {
        if (teamSelected.value) {
          response = await getReservationCode(reservationCode.value, teamSelected.value)
        } else {
          response = await getReservationCode(reservationCode.value)
        }

        responsePaymentMethods = await getLandingUserPaymentMethods()
      } else if (teamSelected.value) {
        response = await getReservationOpenCode(reservationCode.value, teamSelected.value)
      } else {
        response = await getReservationOpenCode(reservationCode.value)
      }

      if (response && response.ok) {
        dataParams.value = response.data
        matchCode.value = dataParams.value.match_code

        if (dataParams.value && dataParams.value.is_challenge === 'Y' && dataParams.value.team == null && team) {
          router.replace({
            name: 'reservation-challenge',
            params: {
              code: reservationCode.value,
              team: teamSelected.value,
              matchCode: matchCode.value,
              payType: paymentType.value,
            },
          })
        }

        // remove team
        // if (!dataParams.value.can_checkout) router.replace({ name: 'match', params: { code: dataParams.value.match_code } })
        if (dataParams.value.organizer_paid) router.replace({ name: 'match', params: { code: dataParams.value.match_code, team: teamSelected.value } })
        else {
          if (!hasLastPaymentMethod.value) {
            showChangePaymentMethod.value = true

            // && !isMozilla.value
            if (!isInitialized.value) await initStripe()
          }

          // if (!!userLogged.value && dataParams.value && dataParams.value.is_organizer && showPay) openUpdatePay(showPay)

          //  remove team
          if (!!userLogged.value && !dataParams.value.is_organizer) {
            onShowNotOrganizer()
          }
          let paymentMethodsParams = []
          const newPaymentMethod = {
            id: 0,
            last_4: null,
            exp_month: null,
            exp_year: null,
            brand: 'Add new card',
            exp_date_str: null,
          }
          if (responsePaymentMethods && responsePaymentMethods.ok && responsePaymentMethods.data) {
            paymentMethodsParams = decryptData(responsePaymentMethods.data)
            if (paymentMethodsParams.legth) {
              const finded = paymentMethodsParams.find(e => e.id === 0)
              if (!finded) paymentMethodsParams.unshift(newPaymentMethod)
            } else paymentMethodsParams.unshift(newPaymentMethod)
          } else {
            paymentMethodsParams.unshift(newPaymentMethod)
          }
          paymentMethods.value = paymentMethodsParams
        }
      }
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      paymentMethods.value = []
      privateLanding.value = false

      await initReservation()
    }

    // remove team
    const onChangePlayersQty = async playersQty => {
      await changeMatchPlayersQty(matchCode.value, {
        players_qty: playersQty,
        team: teamSelected.value,
      })
      openUpdateSplit(false)
      await initReservation()
    }

    const onContinue = async () => {
      if (userLogged.value) {
        openUpdatePay(true)
      } else {
        onLogin(true)
      }
    }

    const setPaymentType = async val => {
      paymentType.value = val

      if (showChangePaymentMethod.value) {
        await updateElements()
      }
    }

    const onChangePaymentMethod = async () => {
      showChangePaymentMethod.value = true

      // && !isMozilla.value
      if (!isInitialized.value) await initStripe()
      else {
        closeContainarLoading()
      }
    }

    watch([isDialogSplit], () => {
      if (isDialogSplit.value) selectedSplit.value = -1
    })

    onMounted(async () => {
      await initReservation()
    })

    return {
      privateLanding,
      dataParams,
      isDialogPay,
      isDialogSplit,
      matchCode,
      paymentMethods,
      userData,
      selectedSplit,
      paymentType,
      userLogged,
      isMozilla,
      onlyNewCard,

      // remove team
      teamSelected,

      isLoading,
      isInitialized,
      isDeclined,
      form,
      containarLoading,
      showChangePaymentMethod,
      hasLastPaymentMethod,

      // computed
      phoneCodeOptions,

      // methods
      formatCurrency,
      onLogin,
      onLogout,
      openUpdatePay,
      openUpdateSplit,
      payOrderVideo,
      onPay,
      onContinueAndPay,
      formatStdDate,
      onChangePlayersQty,
      initStripe,
      onContinue,
      onChangePaymentMethod,
      setPaymentType,
      datepass,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      // validations
      validators: {
        required,
        minLengthValidator,
        integerValidator,
      },

      t,

      icons: {
        mdiCreditCardOutline,
        mdiFoodAppleOutline,
        mdiCalendar,
        mdiClose,
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';

// .loader {
//     border: 5px solid #f3f3f3; /* Light grey */
//     border-top: 5px solid #333333; /* Blue */
//     border-radius: 50%;
//     width: 50ch;
//     height: 50ch;
//     animation: spin 2s linear infinite;
//  }

// @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
// }

// .hide-loader{
//   display:none;
// }

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #333333; /* Blue */
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
</style>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>

<style lang="scss" scoped>
.v-input__append-inner {
  max-width: auto 0px;
}

.white-border {
  border: 2px solid #ffffff !important;
}

.app-content-container {
  padding: 0px !important;
}

.card-img {
  height: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
</style>
